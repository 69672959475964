@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Content {
  padding: 60px 0;
}

.Container {
  padding: 0 60px;
}

/* Styles for mobile view */
@media (max-width: 768px) {
  .Container {
    padding: 0 15px; /* Remove padding for mobile view */
  }
}

.AnalyticsContainer {
  background-color: #e6eaef;
}

h1.pageTitle {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  color: #000000;
}

h2.pageSubTitle {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: normal;
  color: #000000;
}

.pageText {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
}

.pageHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: self-start;
  padding-bottom: 50px;
  border-bottom: 1px solid;
  border-color: rgba(217, 223, 233, 0.4);
}

.pageToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 40px 16px;
  border-bottom: 1px solid;
  border-color: rgba(217, 223, 233, 0.4);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.fullImg {
  width: 100%;
}

.RevImg {
  height: 100%;
  width: auto;
  text-align: center;
}

.MuiInputBase-adornedEnd {
  padding: 0 !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Safari */
}
